<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    custom-class="tmc-dialog"
    top="30vh"
    width="580px"
    @closed="dialogClosed()"
  >
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px" :status-icon="false" hide-required-asterisk>
      <el-form-item key="loginName" prop="loginName" label="用户名" v-if="type === 1">
        <el-input v-model="dataForm.loginName" placeholder="请输入新邮箱地址" disabled></el-input>
      </el-form-item>

      <el-form-item key="phonenumber" prop="phonenumber" label="手机号" v-if="type === 1">
        <div class="inline-item">
          <el-input v-model="dataForm.phonenumber" placeholder="请输入新手机号" :disabled="phoneDisabled" style="width: 80%">
            <el-select v-model="dataForm.crownCode" slot="prepend" placeholder="请选择" :disabled="phoneDisabled">
              <el-option value="+86"></el-option>
            </el-select>
          </el-input>
          <el-button type="primary" @click="changePhone()">{{ phoneDisabled ? '更改' : '确定' }}</el-button>
        </div>
      </el-form-item>

      <el-form-item key="email" prop="email" label="邮箱" v-if="type === 1">
        <div class="inline-item">
          <el-input v-model="dataForm.email" placeholder="请输入新邮箱地址" :disabled="emailDisabled" style="width: 80%"></el-input>
          <el-button type="primary" @click="changeEmail()">{{ emailDisabled ? '更改' : '确定' }}</el-button>
        </div>
      </el-form-item>

      <el-form-item key="password" label="新密码" prop="password" label-width="70px" v-if="type === 2">
        <el-input v-model="dataForm.password" :type="passwordType" placeholder="输入以修改密码" style="width: 97%">
          <div slot="suffix" @click="showPassword = !showPassword" class="password-suffix">
            <i v-if="showPassword" class="el-input__icon el-icon-view"></i>
            <el-image v-else :src="require(`@/assets/img/icon_preview_close.png`)"> </el-image>
          </div>
          ></el-input
        >
        <span style="position: absolute; right: -11px">
          <el-tooltip effect="dark" placement="top" :tabindex="-1">
            <div slot="content">
              1.长度为8~14个字符<br />2.字母、数字以及特殊符号至少包含2种<br />3.不允许有空格、中文<br />
              4.区分大小写
            </div>
            <el-image :src="require(`@/assets/img/icon_info_def.png`)"> </el-image>
          </el-tooltip>
        </span>
      </el-form-item>

      <el-form-item key="comfirmPassword" label="确认密码" prop="comfirmPassword" label-width="70px" v-if="type === 2">
        <el-input v-model="dataForm.comfirmPassword" :type="comfirmPasswordType" placeholder="输入以确认密码" style="width: 97%">
          <div slot="suffix" @click="showComfirmPassword = !showComfirmPassword" class="password-suffix">
            <i v-if="showComfirmPassword" class="el-input__icon el-icon-view"></i>
            <el-image v-else :src="require(`@/assets/img/icon_preview_close.png`)"> </el-image>
          </div>
          ></el-input
        >
      </el-form-item>

      <el-form-item prop="userSpace" label="账户容量" v-if="type === 3">
        <el-input v-model="dataForm.userSpace" disabled></el-input>
      </el-form-item>

      <el-form-item prop="capacity" label="容量上限(GB)" v-if="type === 3">
        <div class="inline-item">
          <el-input-number
            v-model="dataForm.capacity"
            :precision="0"
            :min="0"
            :controls="!capacityDisabled"
            controls-position="right"
            :disabled="capacityDisabled"
            style="width: 80%"
          ></el-input-number>
          <el-button type="primary" @click="changeCpacity()">{{ capacityDisabled ? '更改' : '确定' }}</el-button>
        </div>
      </el-form-item>

      <el-form-item label="功能权限" v-if="type === 3">
        <span slot="label">
          功能权限
          <el-tooltip placement="top">
            <div slot="content">用户权限增加后，无法进行删除</div>
            <i class="el-icon-info"></i>
          </el-tooltip>
        </span>
        <div class="inline-item">
          <el-cascader
            ref="cascader"
            v-model="permissionList"
            @change="handleChange"
            :options="options"
            :props="props"
            :show-all-levels="false"
            :disabled="authDisabled"
            style="width: 80%"
          ></el-cascader>
          <el-button type="primary" @click="changeAuth()">{{ authDisabled ? '更改' : '确定' }}</el-button>
        </div>
      </el-form-item>

      <el-form-item v-if="type === 2" style="text-align: center" label-width="0">
        <el-button type="primary" @click="submit">保存</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'UserUpdate',
  data() {
    return {
      dialogVisible: false,
      type: 0,
      title: '',
      dataForm: {},
      dataRule: {
        email: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('邮箱不能为空'))
              } else if (
                !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
                  value
                )
              ) {
                callback(new Error('请输入正确的邮箱'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        phonenumber: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('手机号不能为空'))
              } else if (!/^(?:(?:\+|00)86)?1\d{10}$/g.test(value)) {
                callback(new Error('请输入正确的手机号'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('密码不能为空'))
              } else if (!/(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,14}$/g.test(value)) {
                callback(new Error('请设置长度为8~14个字符，字母、数字以及特殊符号至少包含2种，无空格和中文的密码'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        comfirmPassword: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('确认密码不能为空'))
              } else if (this.dataForm.password !== value) {
                callback(new Error('两次密码输入不一致'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        capacity: [{ required: true, message: '容量上限不能为空', trigger: 'blur' }]
      },
      showPassword: false,
      showComfirmPassword: false,
      phoneDisabled: true,
      emailDisabled: true,
      capacityDisabled: true,
      authDisabled: true,
      props: { multiple: true },
      options: [],
      permissionList: []
    }
  },
  computed: {
    passwordType() {
      return this.showPassword ? 'text' : 'password'
    },
    comfirmPasswordType() {
      return this.showComfirmPassword ? 'text' : 'password'
    }
  },
  methods: {
    init(row = {}, type) {
      this.type = type
      this.dataForm = JSON.parse(JSON.stringify(row))
      this.dataForm.crownCode = '+86'
      this.dataForm.password = ''
      this.$set(this.dataForm, 'comfirmPassword', '')
      this.phoneDisabled = true
      this.emailDisabled = true
      this.capacityDisabled = true
      this.authDisabled = true
      this.permissionList = []
      this.options = [
        {
          value: 1,
          label: '云盘'
        },
        {
          value: 99,
          label: '智能巡检',
          children: [
            {
              value: 2,
              label: '智能拍摄集',
              parent: 99
            },
            {
              value: 4,
              label: '规则诊断集',
              parent: 99
            }
          ]
        },
        {
          value: 8,
          label: '视频预览'
        }
      ]
      if (this.type === 1) {
        //  账号信息
        this.title = '账号信息'
      } else if (this.type === 2) {
        // 重置密码
        this.title = '重置密码'
      } else if (this.type === 3) {
        // 账户权限
        this.title = '账户权限'
        this.getPermission()
        return
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    },
    // 获取账户容量
    getPermission() {
      this.$http({
        url: this.$http.adornUrl(`/api/v1/user/userSpace`),
        method: 'get',
        params: this.$http.adornParams({
          userId: this.dataForm.userId
        })
      }).then((res) => {
        if (res.code === 0) {
          // 容量
          this.dataForm.capacity = this.dataForm.capacity / 1024 / 1024
          this.dataForm.use = (res.data || {}).fileSize
          this.dataForm.userSpace = this.changeUnit(this.dataForm.use) + ' / ' + this.dataForm.capacity + 'GB'
          //权限
          const list = []
          for (let i = 0; i < 4; i++) {
            if ((this.dataForm.permission >> i) & 1) {
              const result = Math.pow(2, i)
              for (let j = 0; j < this.options.length; j++) {
                const item = this.options[j]
                // 一级
                if (item.value === result) {
                  item.disabled = true
                  list.push([result])
                  break
                }
                // 二级
                const children = item.children || []
                let breakFlag = false
                for (let k = 0; k < children.length; k++) {
                  if (children[k].value === result) {
                    children[k].disabled = true
                    list.push([children[k].parent, result])
                    breakFlag = true
                    break
                  }
                }
                if (breakFlag) {
                  break
                }
              }
            }
          }
          this.permissionList = list
          this.dialogVisible = true
          this.$nextTick(() => {
            this.$refs.dataForm.clearValidate()
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleChange() {
      this.$nextTick(() => {
        const checked = this.$refs.cascader.getCheckedNodes(true).map((item) => {
          return item.value
        })

        this.dataForm.permission =
          checked.length > 0
            ? checked.reduce((a, b) => {
                return a + b
              })
            : 0
      })
    },
    changePhone() {
      if (this.phoneDisabled) {
        this.phoneDisabled = false
      } else {
        this.$refs.dataForm.validateField('phonenumber', (valid) => {
          if (!valid) {
            const { userId, phonenumber } = this.dataForm
            this.$http({
              url: this.$http.adornUrl(`/api/v1/user/info`),
              method: 'POST',
              params: this.$http.adornParams({
                userId,
                phone: phonenumber
              })
            }).then((res) => {
              if (res.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {}
                })
                this.phoneDisabled = true
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      }
    },
    changeEmail() {
      if (this.emailDisabled) {
        this.emailDisabled = false
      } else {
        this.$refs.dataForm.validateField('email', (valid) => {
          if (!valid) {
            const { userId, email } = this.dataForm
            this.$http({
              url: this.$http.adornUrl(`/api/v1/user/info`),
              method: 'POST',
              params: this.$http.adornParams({
                userId,
                email
              })
            }).then((res) => {
              if (res.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {}
                })
                this.emailDisabled = true
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      }
    },
    changeCpacity() {
      if (this.capacityDisabled) {
        this.capacityDisabled = false
      } else {
        this.$refs.dataForm.validateField('capacity', (valid) => {
          if (!valid) {
            const { userId, capacity } = this.dataForm
            this.$http({
              url: this.$http.adornUrl(`/api/v1/user/permission`),
              method: 'POST',
              params: this.$http.adornParams({
                userId,
                capacity: capacity * 1024 * 1024
              })
            }).then((res) => {
              if (res.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {}
                })
                this.dataForm.userSpace = this.changeUnit(this.dataForm.use) + ' / ' + this.dataForm.capacity + 'GB'
                this.capacityDisabled = true
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      }
    },
    changeAuth() {
      if (this.authDisabled) {
        this.authDisabled = false
      } else {
        this.$refs.dataForm.validate((valid) => {
          if (valid) {
            const { userId, permission } = this.dataForm
            this.$http({
              url: this.$http.adornUrl(`/api/v1/user/permission`),
              method: 'POST',
              params: this.$http.adornParams({
                userId,
                permission
              })
            }).then((res) => {
              if (res.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {}
                })
                this.authDisabled = true
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      }
    },
    submit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          const { userId, password } = this.dataForm
          this.$http({
            url: this.$http.adornUrl(`/api/v1/user/info`),
            method: 'POST',
            params: this.$http.adornParams({
              userId,
              password
            })
          }).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.dialogVisible = false
                }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    dialogClosed() {
      this.$emit('refreshDataList')
    },
    // 单位换算
    changeUnit(size = 0) {
      let num = size
      let unit = 'KB'
      // MB
      if (num >= 1024) {
        num = Math.ceil(num / 1024)
        unit = 'MB'
        // GB
        if (num >= 1024) {
          num = Math.ceil((num * 10) / 1024) / 10
          if (num.toString().split('.')[1] === '0') {
            num = num.toString().split('.')[0]
          }
          unit = 'GB'
        }
      }

      return num + unit
    }
  }
}
</script>

<style lang="scss" scoped>
// 区号选择前缀
::v-deep .input-with-select .el-input-group__prepend {
  background-color: #fff;
}

::v-deep .el-select .el-input {
  width: 80px;
  .el-form-item--feedback .el-input__validateIcon {
    display: none;
  }
}

.password-suffix {
  width: 25px;
  height: 100%;
  cursor: pointer;
}

::v-deep .el-image__inner {
  vertical-align: middle;
}

.inline-item {
  display: flex;
  justify-content: space-between;
}
</style>
