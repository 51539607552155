<template>
  <el-container style="height: 100%" @dragenter.native="dragenter">
    <el-header style="padding: 0; height: 40px">
      <div id="navigation">用户管理</div>
    </el-header>

    <el-container style="height: calc(100% - 40px)">
      <div style="padding: 20px 20px 0 20px">
        <el-form :inline="true" ref="dataForm" :model="dataForm" @keyup.enter.native="getDataList(true)">
          <el-form-item prop="name">
            <el-input v-model="dataForm.name" placeholder="用户名" clearable></el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input v-model="dataForm.phone" placeholder="手机号" clearable></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input v-model="dataForm.email" placeholder="邮箱地址" clearable></el-input>
          </el-form-item>
          <el-form-item prop="delFlag" label="用户状态">
            <el-select v-model="dataForm.delFlag" placeholder="请选择" clearable>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList(true)" type="primary">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="refreshForm()" style="background: rgb(30, 30, 242, 0.1); color: #5053dd">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="exportData()" :loading="downloadLoading" type="primary">导出</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-main style="padding: 0 20px 20px 20px; overflow: hidden">
        <div style="height: 1px; background: #d4d6d9; opacity: 0.5; margin-bottom: 22px"></div>
        <div class="main">
          <el-table
            v-if="dataList.length"
            :data="dataList"
            v-loading="tabLoding"
            header-cell-class-name="tmc_table_header"
            row-class-name="tmc_table_row"
            style="width: 100%"
            height="calc(100vh - 367px)"
          >
            <el-table-column prop="loginName" header-align="center" align="center" label="用户名" min-width="100"></el-table-column>
            <el-table-column prop="phonenumber" header-align="center" align="center" label="手机号" min-width="150"></el-table-column>
            <el-table-column prop="email" header-align="center" align="center" label="邮箱地址" min-width="225"></el-table-column>
            <el-table-column prop="delFlag" header-align="center" align="center" label="状态" width="100">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.delFlag === '0'">正常</el-tag>
                <el-tag type="info" v-else>已注销</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="loginDate" header-align="center" align="center" label="最近一次登录时间" width="200"> </el-table-column>
            <el-table-column prop="loginIp" header-align="center" align="center" label="最近一次登录IP" width="180"> </el-table-column>
            <el-table-column prop="capacity" header-align="center" align="center" label="账户容量" width="150">
              <template slot-scope="scope"> {{ changeUnit(scope.row.usedCapacity || 0) + '/' + changeUnit(scope.row.capacity || 0) }} </template>
            </el-table-column>
            <el-table-column prop="createTime" header-align="center" align="center" label="注册时间" width="200"> </el-table-column>
            <el-table-column header-align="center" align="center" label="操作" width="300">
              <template slot-scope="scope">
                <template v-if="scope.row.delFlag === '0'">
                  <el-button type="text" @click="validAuth(scope.row, 1)">编辑信息</el-button>
                  <el-button type="text" @click="validAuth(scope.row, 2)">重置密码</el-button>
                  <el-button type="text" @click="validAuth(scope.row, 3)">编辑权限</el-button>
                </template>
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table>
          <div v-else style="height: 100%; text-align: center">
            <el-image :src="require('@/assets/img/no-data.png')" fit="contain" style="margin: 10% 0 24px 0"> </el-image>
            <div style="font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #73767a">啊哦~这里没有数据呢~</div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <div v-if="dataList.length" style="margin-top: 20px">
          <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </el-footer>
    </el-container>
    <el-dialog title="权限验证" :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="tmc-dialog" top="30vh" width="580px">
      <el-form :model="checkForm" :rules="dataRule" ref="checkForm" :status-icon="false" hide-required-asterisk @keyup.enter.native="toCheck()">
        <el-form-item prop="password">
          <el-input v-model="checkForm.password" :type="passwordType" placeholder="请输入密码以验证管理员权限">
            <div slot="suffix" @click="showPassword = !showPassword" class="password-suffix">
              <i v-if="showPassword" class="el-input__icon el-icon-view"></i>
              <el-image v-else :src="require(`@/assets/img/icon_preview_close.png`)"> </el-image>
            </div>
            ></el-input
          >
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toCheck()">确 定</el-button>
      </div>
    </el-dialog>
    <user-update v-if="updateVisible" ref="update" @refreshDataList="getDataList"></user-update>
  </el-container>
</template>

<script>
import UserUpdate from '@/views/UserUpdate'
export default {
  components: { UserUpdate },
  name: 'User',
  data() {
    return {
      dataForm: {
        name: '',
        phone: '',
        email: '',
        delFlag: 0
      },
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      tabLoding: false,
      dataListSelections: [],
      dataList: [],
      updateVisible: false,
      options: [
        {
          label: '正常',
          value: 0
        },
        {
          label: '已注销',
          value: 2
        }
      ],
      downloadLoading: false,
      dialogVisible: false,
      showPassword: false,
      selectRow: {},
      type: 1,
      checkForm: {
        password: ''
      },
      dataRule: {
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    passwordType() {
      return this.showPassword ? 'text' : 'password'
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    // 导出
    exportData() {
      const { name, phone, email, delFlag } = this.dataForm

      this.downloadLoading = true
      // 获取数据
      this.$http({
        url: this.$http.adornUrl('/api/v1/user/list'),
        method: 'get',
        params: this.$http.adornParams({
          name,
          phone,
          email,
          delFlag,
          pageNum: 1,
          pageSize: 999999
        })
      })
        .then((res) => {
          if (res.code === 0) {
            const data = res.data || {}
            // 获取当前展示的表格数据
            const list = data.list || []
            list.forEach((item) => {
              item.delFlagName = item.delFlag === '0' ? '正常' : '已注销'
            })

            // 懒加载该用法
            import('@/utils/Export2Excel').then((excel) => {
              // 设置导出表格的头部
              const tHeader = [
                '用户名',
                '手机号',
                '邮箱地址',
                '状态',
                '最近一次登录时间',
                '最近一次登录IP',
                '占用容量（KB）',
                '账户容量（KB）',
                '注册时间'
              ]
              // 设置要导出的属性
              const filterVal = ['loginName', 'phonenumber', 'email', 'delFlagName', 'loginDate', 'loginIp', 'usedCapacity', 'capacity', 'createTime']

              // 将要导出的数据进行一个过滤
              const data = this.formatJson(filterVal, list)
              // 调用我们封装好的方法进行导出Excel
              excel.export_json_to_excel({
                // 导出的头部
                header: tHeader,
                // 导出的内容
                data,
                // 导出的文件名称
                filename: '用户管理',
                // 导出的表格宽度是否自动
                autoWidth: true,
                // 导出文件的后缀类型
                bookType: 'csv'
              })

              this.downloadLoading = false
            })
          } else {
            this.$message.error(res.msg)
            this.downloadLoading = false
          }
        })
        .catch(() => {
          this.downloadLoading = false
        })
    },
    // 对要导出的内容进行过滤
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j]
        })
      )
    },
    toCheck() {
      this.$refs.checkForm.validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl('/api/v1/user/checkAdmin'),
            method: 'get',
            params: this.$http.adornParams({
              adminPassword: this.checkForm.password
            })
          }).then((res) => {
            if (res && res.code === 0) {
              if (res.data) {
                this.updateVisible = true
                this.$nextTick(() => {
                  this.$refs.update.init(this.selectRow, this.type)
                })
                this.dialogVisible = false
              } else {
                this.$message.error('密码错误')
              }
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    // 鉴权
    validAuth(row, type) {
      this.selectRow = row
      this.type = type
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.checkForm.resetFields()
      })
    },
    getDataList(flag) {
      if (flag) {
        this.pageIndex = 1
      }

      const { name, phone, email, delFlag } = this.dataForm
      this.tabLoding = true
      this.$http({
        url: this.$http.adornUrl('/api/v1/user/list'),
        method: 'get',
        params: this.$http.adornParams({
          name,
          phone,
          email,
          delFlag,
          pageNum: this.pageIndex,
          pageSize: this.pageSize
        })
      })
        .then((res) => {
          if (res.code === 0) {
            const data = res.data || {}
            this.dataList = data.list || []
            this.totalPage = data.total
          } else {
            this.$message.error(res.msg)
          }
          this.tabLoding = false
        })
        .catch(() => {
          this.tabLoding = false
        })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    refreshForm() {
      this.$refs.dataForm.resetFields()
    },
    // 单位换算
    changeUnit(size) {
      let num = size
      let unit = 'KB'
      // MB
      if (num >= 1024) {
        num = Math.ceil(num / 1024)
        unit = 'MB'
        // GB
        if (num >= 1024) {
          num = Math.ceil((num * 10) / 1024) / 10
          if (num.toString().split('.')[1] === '0') {
            num = num.toString().split('.')[0]
          }
          unit = 'GB'
        }
      }

      return num + unit
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  overflow: hidden;
}
#navigation {
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  margin-bottom: 22px;
}
.password-suffix {
  width: 25px;
  height: 100%;
  cursor: pointer;
}
::v-deep .el-image__inner {
  vertical-align: middle;
}
</style>
